window.HBMLP = (function () {
  'use strict';

  // Private
  var ajaxSecured = true;
  var translations = {};

  // Public
  var module = window.HBM;

  module.initUploader = function () {
    var $uploadAreas = $('.uploader');
    $uploadAreas.each(function () {
      var attr = $(this).attr('data-group');
      var postfix = attr ? '-' + attr : '';

      var $uploads = $('#uploads-list' + postfix);
      var $uploader = $('#uploader' + postfix);
      var $uploaderDropzone = $('#uploader-dropzone' + postfix);

      if (($uploader.length > 0) && ($uploaderDropzone.length > 0)) {
        // Disable auto discover for all elements (with class ".dropzone")
        Dropzone.autoDiscover = false;
        $uploaderDropzone.addClass('dropzone').dropzone({
          url: $uploader.attr('data-url'),
          dictDefaultMessage: 'Dokument per Drag & Drop einfach reinziehen oder klicken zum uploaden.',
          autoProcessQueue: true,
          addRemoveLinks: true,
          acceptedFiles: 'image/*,application/pdf,.ot,.rtt,.eot,.woff,.woff2,.ttf,.doc,.docx,.xls,.xlsx',
          clickable: false
        });

        var myDropzone = $uploaderDropzone.get(0).dropzone;
        myDropzone
          .on('success', function (file, response) {
            HBMLP.flashNotificationFromArray(response['messages'] || []);
          })
          .on('complete', function (file) {
            window.setTimeout(function () {
              myDropzone.removeFile(file);
            }, 2000);
          })
          .on('queuecomplete', function () {
            $uploads.hbm_reload({
              callback: function () {
                HBMLP.initUploads($uploads);
              }
            });
          });

        $uploaderDropzone.on('click', function (event) {
          event.preventDefault();
          location.href = $(this).attr('data-upload-form-url');
        });
      }
    });
  };

  module.initUploads = function ($elements) {
    $elements.each(function () {
      var $element = $(this);
      HBMLP.initSortables($element.parent(), {
        force: true
      });

      HBMLP.initNewestDocument($element);

      $element.find('[data-toggle="tooltip"]').tooltip({container: 'body'});

      $element.find('.date-picker-documents').datepicker({
        yearRange: '-20:+5',
        dateFormat: 'yy-mm-dd',
        changeYear: true,
        changeMonth: true,
        onSelect: function (date) {
          HBMLP.inlineEdit($(this), date, {
            onSuccess: function (el) {
              var iconElement = el.parent().find('.fa-calendar-day');
              iconElement.removeClass('text-danger');
              iconElement.addClass('text-primary');
              el.addClass('badge-uploads');
              el.removeClass('badge-uploads-danger');

              el.closest('.uploads-wrapper-card.uploads-wrapper-company').attr('data-sort-document-creation-date', date);

              HBMLP.initNewestDocument(el);
            }
          });
        }
      });
    });
  };

  module.initNewestDocument = function ($element) {
    var $uploadList = $element.closest('.uploads-list-aside');
    var $uploads = $uploadList.find('.uploads-wrapper-card.uploads-wrapper-company');
    if ($uploads.length === 0) {
      return;
    }

    // Instantiate Shuffle
    var Shuffle = window.Shuffle;
    var shuffleInstance = new Shuffle($uploadList, {
      itemSelector: '.uploads-wrapper-card.uploads-wrapper-company',
      speed: 600,
      useTransforms: false
    });

    // Sort the uploads on the shuffleInstance
    shuffleInstance.sort({
      compare: function (a, b) {
        var valA = a.element.dataset.sortDocumentCreationDate || '';
        var valB = b.element.dataset.sortDocumentCreationDate || '';
        return valB.localeCompare(valA);
      }
    });

    // Remove "newest upload indicator"
    $uploads.find('.upload-newest').remove();
    // Set "newest upload indicator"
    var creationDate = shuffleInstance.sortedItems[0].element.dataset.sortDocumentCreationDate;
    if(creationDate !== '') {
      var $newestUpload = $('[data-sort-document-creation-date=' + creationDate + ']');
      $newestUpload.first().prepend('<div class="upload-newest"><i class="fas fa-star text-white"></i></div>');
    }
  };

  module.inlineEdit = function ($element, data, options) {
    var settings = $.extend({
      onSuccess: function () {},
      onError: function () {}
    }, options);

    $element.removeClass('inline-edit-error inline-edit-success');

    if (typeof data === 'undefined') {
      data = $element.html().replace('<br>', "\n").replace(/<\/?em>/g, "").trim();
    }

    if ($element.data('original') === data) {
      return;
    }

    $.post($element.attr('data-inline-edit'), {data: data}, function (response) {
      if (response['success']) {
        $element.addClass('inline-edit-success');
        if (response['data'] === '') {
          $element.html('<em>n/a</em>');
        }
        else {
          $element.html(response['data']);
        }
        settings['onSuccess']($element);
      }
      else {
        $element.addClass('inline-edit-error');
        HBMLP.flashNotification('<strong class="alert-danger">Inline editing failed</strong><br />' + response['error'], 'danger', 4000);
        settings['onError']($element);
      }
    });
  };

  module.initAjaxSecured = function ($body, $elements) {
    // Load valur from local storage (if possible)
    if (window.localStorage && window.localStorage.getItem('hbmlp-ajax-secured')) {
      ajaxSecured = JSON.parse(window.localStorage.getItem('hbmlp-ajax-secured'));
    }

    $elements.on('click', function (event) {
      event.preventDefault();

      module.handleAjaxSecured(!ajaxSecured, $body, $elements);
    });

    // Init buttons and boty.
    module.handleAjaxSecured(ajaxSecured, $body, $elements);
  };

  module.handleAjaxSecured = function (newAjaxSecured, $body, $elements) {
    ajaxSecured = newAjaxSecured;

    if (window.localStorage) {
      window.localStorage.setItem('hbmlp-ajax-secured', JSON.stringify(ajaxSecured));
    }

    if (ajaxSecured === false) {
      $elements.addClass('active');
      $body.removeClass('ajax-secured');
    }
    if (ajaxSecured === true) {
      $elements.removeClass('active');
      $body.addClass('ajax-secured');
    }
  };

  module.translate = function (key, translation) {
    if (typeof translation === 'undefined') {
      if (Object.hasOwn(translations, key)) {
        return translations[key];
      }
      return key;
    }

    translations[key] = translation;
  };

  return module;

})();
