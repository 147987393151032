// Has to be defined here, so that the initialization in Vue.mounted befenits from this settings as well.
$.fn.select2.defaults.set('theme', 'bootstrap4');
$.fn.select2.defaults.set('width', '100%');
// Disable deletion with backspace.
// See: https://github.com/select2/select2/issues/3354
$.fn.select2.amd.require(['select2/selection/search'], function (Search) {
  'use strict';
  Search.prototype.searchRemoveChoice = function (decorated, item) {
  };
}, null, true);

$(document).ready(function ($, HBMLP, ClipboardJS) {
  'use strict';

  // Works in combination with AjaxAuthenticationListener.php
  $(document).ajaxError(function (event, jqXHR) {
    if ((jqXHR.status === 401) || (jqXHR.status === 403)) {
      if (confirm(HBMLP.translate('js.not_logged_in'))) {
        window.location.reload();
      }
    }
  });

  let $body = $('body');

  HBMLP.initAjaxLinks($body);
  HBMLP.initAjaxButtons($body);
  HBMLP.initAjaxSecured($body, $('#ajax-secured-toggle'));
  HBMLP.initDateTimePicker($body, {yearRange: '-100:+20'});
  HBMLP.initSortables($body);
  HBMLP.initUploader();
  HBMLP.initToggablePasswords();

  $('[data-card-collapsible]').hbm_initCollapsibleCards();

  $body
    .on('dragenter dragstart dragend dragleave dragover drag drop', '#hbmlp-aside', function (event) {
      event.preventDefault();
    })
    .on('dragover', '#hbmlp-aside', function (event) {
      $(this).addClass('droppable');
    })
    .on('dragleave', '#hbmlp-aside', function (event) {
      $(this).removeClass('droppable');
    })
    .on('drop', '#hbmlp-aside-drop', function (event) {
      let url = $(this).attr('data-url');

      let item = event.originalEvent.dataTransfer.items[0] || null;
      if (item && (item.kind === 'string') && (item.type === 'text/plain')) {
        item.getAsString(function (s) {
          if (confirm('Infos für "' + s + '" anzeigen?')) {
            window.location = url + '?search=' + encodeURIComponent(s);
          }
          else {
            $(this).removeClass('droppable');
          }
        });
      }
    });

  /** ********************************************************************** **/

  $('.copy-to-clipboard').tooltip({
    title: function () {
      return this.dataset.title;
    }
  });

  let clipboard = new ClipboardJS('.copy-to-clipboard');

  clipboard.on('success', function (e) {
    let $element = $(e.trigger);

    if (!$element.hasClass('no-tooltip')) {
      let title = $element.attr('data-title');

      $(e.trigger).attr('data-title', HBMLP.translate('js.copied')).tooltip('show');

      window.setTimeout(function () {
        $(e.trigger).tooltip('hide').attr('data-title', title);
      }, 1000);
    }
  });

  /** ********************************************************************** **/

  $('.clipboardable-trigger').tooltip({
    title: function () {
      return this.dataset.titleFormat.replace(':title', this.dataset.title);
    },
    html: true,
    placement: 'right',
    customClass: 'clipboardable-tooltip'
  });

  let clipboardableTrigger = new ClipboardJS('.clipboardable-trigger');

  clipboardableTrigger.on('success', function (e) {
    $(e.trigger).attr('data-title-format', ':title kopiert <i class="fa fa-check"></i>').tooltip('show');

    let $container = $(e.trigger).closest('.clipboardable');
    $container.addClass('clipboardable-success');

    window.setTimeout(function () {
      $(e.trigger).tooltip('hide').attr('data-title-format', ':title kopieren');

      $container.removeClass('clipboardable-success');
    }, 2000);
  });

  $('.clipboardable-trigger').on('mouseenter', function (event) {
    $(this).closest('.clipboardable').addClass('hovered');
  });
  $('.clipboardable-trigger').on('mouseleave', function (event) {
    $(this).closest('.clipboardable').removeClass('hovered');
  });

  /** ********************************************************************** **/

  $('form:not(.datagrid-menu-search)').on('keypress', 'input', function (event) {
    if (event.which === 13) {
      event.preventDefault();
      return false;
    }
  });

  $body.on('paste', '.inline-edit[contenteditable]', function (event) {
    let text = null;
    if (event.clipboardData) {
      text = event.clipboardData.getData('text');
    }
    else if (window.clipboardData) {
      text = window.clipboardData.getData('text');
    }
    else if (event.originalEvent && event.originalEvent.clipboardData) {
      text = event.originalEvent.clipboardData.getData('text');
    }

    let selection = window.getSelection();
    if (!selection.rangeCount) {
      return false;
    }
    selection.deleteFromDocument();
    selection.getRangeAt(0).insertNode(document.createTextNode(text));

    event.preventDefault();
  });

  $body.on('focusin', '.inline-edit[contenteditable]', function (event) {
    let $element = $(event.target);

    let data = $element.html().replace('<br>', "\n").trim();

    $element.data('original', data);
  });

  $body.on('focusout', '.inline-edit[contenteditable]', function (event) {
    HBMLP.inlineEdit($(event.target));
  });

  $body.on('click', '.embed-pdf', function (event) {
    event.preventDefault();

    let url = $(this).attr('data-src');

    $('.hbmlp-content-main-body').find('[data-embed]').html('<div class="close-embed d-flex justify-content-end"><i style="cursor: pointer;" class="fas fa-times text-primary fa-2x"></i></div><object data="' + url + '#page=1" type="application/pdf" width="100%" height="800" class="d-flex justify-content-center align-items-center"><i class="fa fa-exclamation-triangle fa-7x text-300"></i></object>');
    $('.hbmlp-content-main-body').children('.row').addClass('d-none');
  });

  $body.on('click', '.embed-image', function (event) {
    event.preventDefault();

    let url = $(this).attr('src');
    let datatype = $(this).attr('data-type');

    $('.hbmlp-content-main-body').find('[data-embed]').html('<div class="close-embed d-flex justify-content-end"><i style="cursor: pointer;" class="fas fa-times text-primary fa-2x"></i></div><object data="' + url + '" type="' + datatype + '" style="max-width: 100%; max-height: 500px" class="d-block mx-auto"><i class="fa fa-exclamation-triangle fa-7x text-300"></></i></object>');
    $('.hbmlp-content-main-body').children('.row').addClass('d-none');
  });

  $body.on('click', '.close-embed', function (event) {
    event.preventDefault();

    $('.hbmlp-content-main-body').children('.row').removeClass('d-none');
    $('.hbmlp-content-main-body').find('[data-embed]').html('');
  });

  $(document).on('submit', 'form.datagrid-export-form', function () {
    return confirm(HBMLP.translate('js.export_hint'));
  });

  let $modals = $('.modal');
  $modals.modal();
  $modals.on('hidden.bs.modal', function (event) {
    let $element = $(this);
    let $checkbox = $element.find('#mark-seen-message');

    if ($checkbox.prop('checked')) {
      let identifier = $element.find('#mark-seen-message').val();
      jQuery.post('/profile/seen-message/' + identifier, function (result) {
        if (result['success']) {
          HBMLP.flashNotification(HBMLP.translate('js.marked_as_seen'), 'success');
        }
      });
    }
  });

  $('select.select2:not(.select2-custom)').select2({});
}(jQuery, HBMLP, ClipboardJS));
